'use strict';

app.factory('finFormService', ['$q', '$rootScope', '$http', 'authService', 'broadcastService', '$filter', '$modal', 'controlService', 'httpService', 'finService', function ($q, $rootScope, $http, authService, broadcastService, $filter, $modal, controlService, httpService, finService)
{
    'use strict';

    (function ()
    {
    })();

    class FormInfo
    {
        controller = null;
        formScope = null;
        source = "";
        docType = "";
        isAP = false;
        isAR = false;

        hasUOM = false;
        hasPayterm = false;
        hasCurrency = false;
        hasOU = false;
        hasCOA = false;
        hasContact = false;
        hasContactCOA = false;
        hasCompany = false;
        hasCCID = false;
        hasTax = false;
        hasAccSetting = false;
        hasAprovalSetting = false;
        hasExchangeRate = false;

        hasAdjustment = false;
        hasInterOUAlloc = false;
        hasOneTimeVendor = false;
    };

    class HeaderData
    {
        TransHdrKey = 0;
        ClientKey = 0;
        OUKey = 0;
        OUCode = "";
        CompKey = 0;
        DocNum = null;
        Source = "";
        DocType = "";
        GLDate = (new Date(Date.now()));
        ReverseGLDate = (new Date(Date.now()));
        GLDesc = "";
        AppliedDesc = "";
        FY = 0;
        Period = 0;
        ReverseFY = 0;
        ReversePeriod = 0;
        CurrKey = 0;
        CurrCode = "";
        ExRateToFunc = 0;
        ExRateToLocal = 0;
        Status = "OP";
        DocAmt = 0;
        RecurSchCode = 0;
        IsRecur = 0;
        ReverseTransHdrKey = null;
        IsReverse = 0;
        DueDate = new Date(2999, 11, 31);
        PayTermKey = -1;
        InvoiceDate = (new Date(Date.now()));
        InvNum = "";
        TaxInvNum = "";
        CreatedBy = -1;
        CreatedByCode = null;
        CreatedDate = (new Date(Date.now()));
        UpdatedBy = -1;
        UpdatedByCode = null;
        UpdatedDate = (new Date(Date.now()));

        AprLvl = 0;
        L1ApprovedBy = -1;
        L1ApprovedDate = (new Date("1900-01-01"));
        L2ApprovedBy = -1;
        L2ApprovedDate = (new Date("1900-01-01"));
        L3ApprovedBy = -1;
        L3ApprovedDate = (new Date("1900-01-01"));
        LastApprovedBy = -1;
        LastApprovedByCode = null;
        LastApprovedDate = (new Date("1900-01-01"));
        RejectBy = -1;
        RejectDate = (new Date("1900-01-01"));

        VoidBy = -1;
        VoidByCode = null;
        VoidDate = (new Date("1900-01-01"));
        Reason = "";
        RejectByCode = "";
        EnableImportService = false;
        EnableRoundingAdj = false;
        ReverseDocNum = null;
        ReverseFromDocNum = null;
        gotRetract = false;
        RowState = 0;

        constructor(Source, DocType, ClientKey, CompKey, OUKey, OUCode)
        {
            if (typeof Source == "string")
            {
                this.Source = Source;
            }

            if (typeof DocType == "string")
            {
                this.DocType = DocType;
            }

            if (typeof ClientKey == "number")
            {
                this.ClientKey = ClientKey;
            }
            else
            {
                this.ClientKey = authService.authentication.ClientKey;
            }

            if (typeof CompKey == "number")
            {
                this.CompKey = CompKey;
            }
            else
            {
                this.CompKey = authService.authentication.defaultCompKey;
            }

            if (typeof OUKey == "number")
            {
                this.OUKey = OUKey;
            }
            else
            {
                this.OUKey = authService.authentication.defaultOUKey;
            }

            if (typeof OUCode == "string")
            {
                this.OUCode = OUCode;
            }
            else
            {
                this.OUCode = authService.authentication.defaultOUCode;
            }

            this.CreatedBy = authService.authentication.userKey;
            this.UpdatedBy = authService.authentication.userKey;
            this.L1ApprovedBy = authService.authentication.userKey;
            this.L2ApprovedBy = authService.authentication.userKey;
            this.L3ApprovedBy = authService.authentication.userKey;
            this.LastApprovedBy = authService.authentication.userKey;
            this.RejectBy = authService.authentication.userKey;
            this.VoidBy = authService.authentication.userKey;

            this.CreatedByCode = authService.authentication.userID;
            this.UpdatedByCode = authService.authentication.userID;
            this.LastApprovedByCode = authService.authentication.userID;
            this.RejectByCode = authService.authentication.userID;
            this.VoidByCode = authService.authentication.userID;
        }
    };

    class DetailData
    {
        TransDetKey = 0;
        TransHdrKey = 0;
        AccKey = 0;
        AccNum = "";
        ClientKey = 0;
        OUKey = 0;
        OUCode = "";
        Remarks = "";
        FuncTransAmt = 0;
        LocalTransAmt = 0;
        OrigTransAmt = 0;
        CurrKey = 0;
        CurrCode = "";
        SeqNo = 0;
        ExRateFunc = 0;
        ExRateLocal = 0;
        CCID = "";
        Qty = 0;
        UOMKey = null;
        UOM = "";
        UnitPrice = 0;
        Discount = 0;
        DiscountAmt = 0;
        Ind = "O";
        RefTransDetKey = 0;
        RefTransHdrKey = 0;
        TaxPer = 0;
        RefTax = "";
        TaxKey = 0;

        dirty = false;

        constructor(Source, DocType, ClientKey, CompKey, OUKey, OUCode)
        {
            //if (typeof Source == "string")
            //{
            //    this.Source = Source;
            //}

            //if (typeof DocType == "string")
            //{
            //    this.DocType = DocType;
            //}

            if (typeof ClientKey == "number")
            {
                this.ClientKey = ClientKey;
            }
            else
            {
                this.ClientKey = authService.authentication.ClientKey;
            }

            //if (typeof CompKey == "number")
            //{
            //    this.CompKey = CompKey;
            //}
            //else
            //{
            //    this.CompKey = authService.authentication.defaultCompKey;
            //}

            if (typeof OUKey == "number")
            {
                this.OUKey = OUKey;
            }
            else
            {
                this.OUKey = authService.authentication.defaultOUKey;
            }

            if (typeof OUCode == "string")
            {
                this.OUCode = OUCode;
            }
            else
            {
                this.OUCode = authService.authentication.defaultOUCode;
            }
        }
    };

    class ContactData
    {
        TransDetKey = 0;
        TransHdrKey = 0;
        AccKey = 0;
        AccNum = "";
        AccDesc = "";
        ClientKey = 0;
        OUKey = 0;
        OUCode = "";
        Remarks = "";
        FuncTransAmt = 0;
        LocalTransAmt = 0;
        OrigTransAmt = 0;
        CurrCode = "";
        SeqNo = 0;
        ExRateFunc = 0;
        ExRateLocal = 0;
        CCIDKey = -1;
        ContactCCIDKey = -1;
        CCID = "";
        Qty = 0;
        UOMKey = null;
        UOM = "";
        UnitPrice = 0;
        Discount = 0;
        DiscountAmt = 0;
        Ind = "V";
        RefTransDetKey = 0;
        RefTransHdrKey = 0;
        TaxPer = 0;
        RefTax = "";

        dirty = false;

        constructor(Source, DocType, ClientKey, CompKey, OUKey, OUCode)
        {
            //if (typeof Source == "string")
            //{
            //    this.Source = Source;
            //}

            //if (typeof DocType == "string")
            //{
            //    this.DocType = DocType;
            //}

            if (typeof ClientKey == "number")
            {
                this.ClientKey = ClientKey;
            }
            else
            {
                this.ClientKey = authService.authentication.ClientKey;
            }

            //if (typeof CompKey == "number")
            //{
            //    this.CompKey = CompKey;
            //}
            //else
            //{
            //    this.CompKey = authService.authentication.defaultCompKey;
            //}

            if (typeof OUKey == "number")
            {
                this.OUKey = OUKey;
            }
            else
            {
                this.OUKey = authService.authentication.defaultOUKey;
            }

            if (typeof OUCode == "string")
            {
                this.OUCode = OUCode;
            }
            else
            {
                this.OUCode = authService.authentication.defaultOUCode;
            }
        }
    };

    class OneTimeVendorData
    {
        Type = "";
        VendorName = "";
        EmpyKey = -1;
        Street = "";
        PostCode = "";
        City = "";
        State = "";
        CtryKey = -1;
        Phone = "";
        Fax = ""
    };

    let finFormServiceFactory = {};

    finFormServiceFactory.getParam = function ()
    {
        return new FormInfo();
    };

    finFormServiceFactory.generateForm = function (formInfo)
    {
        //store promisese to allow form to await
        let promises = [];

        //store all data source to allow to refresh
        let dataSources = [];

        if (formInfo instanceof FormInfo)
        {
            let formScope = formInfo.formScope;

            formInfo.isAP = (formInfo.source == "PI" || formInfo.source == "CN" || formInfo.source == "SB");
            formInfo.isAR = (formInfo.source == "SI" || formInfo.source == "DN" || formInfo.source == "SBR");

            //base feature, attach function here
            formScope.base = {
                digest: function ()
                {
                    if (formScope.$root != null && formScope.$root != undefined)
                        if (formScope.$root.$$phase != '$apply' && formScope.$root.$$phase != '$digest')
                            formScope.$apply();
                },
                dirty: false
            };

            //attachment
            (function ()
            {
                //attachment html
                formScope.getInclude = function ()
                {
                    return "../../master/views/attachment.html";
                };

                //attachment parameters
                formScope.attachmentParam =
                {
                    strUniqueID: formScope.strUniqueID,
                    refSubjectCode: $rootScope.AttachmentSubjects.PurchaseInvoiceCode,
                    refSubject: $rootScope.AttachmentSubjects.PurchaseInvoice,
                };

                //attachment child scope
                formScope.attachmentChild = formScope.$new();

                //initialize controller
                formInfo.controller("attachmentController", { $scope: formScope.attachmentChild, attachmentParam: formScope.attachmentParam });

                formScope.showAttach = false;

                formScope.attachment = function ()
                {
                    formScope.showAttach = true;
                    formScope.base.digest();
                };
            })();

            //flags and settings
            (function ()
            {
                //flag to enable inter company billing
                formScope.EnableICB = Array.isArray($rootScope.SubscribeAddOn) ? $rootScope.SubscribeAddOn.includes("ICB") : false;
                //force enable for all
                formScope.EnableICB = true;

                //flag to enable automated ou allocation
                formScope.isAutomatedOUAllocation = authService.authentication.isAutomatedOUAllocation;

                //flag to enable CRNI
                formScope.EnableCRNI = Array.isArray($rootScope.SubscribeAddOn) ? $rootScope.SubscribeAddOn.includes("CRNI") : false;

                //row states
                formScope.RowState =
                {
                    Unchanged: 0,
                    Added: 1,
                    Modified: 2,
                    Deleted: 3
                };
            })();

            //initialize form variables
            (function ()
            {
                formScope.ClientKey = authService.authentication.ClientKey;
                formScope.OUKey = authService.authentication.defaultOUKey;
                formScope.OUCode = authService.authentication.defaultOUCode;
                formScope.OUType = "";
                formScope.CompKey = authService.authentication.defaultCompKey;

                formScope.base.initVar = (function initVar()
                {
                    formScope.base.dirty = false;

                    formScope.newAttachment = true;
                    formScope.firstLoadAttach = true;
                    formScope.origAttaches = [];
                    formScope.attaches = [];
                    formScope.attachesDestroy = [];
                    formScope.attachmentID = "";

                    formScope.taxCurrKey = -1;
                    formScope.funcCurrKey = -1;
                    formScope.convertType = "";
                    formScope.singleCurrFlag = true;

                    formScope.hasBaseLine = false;
                    formScope.hasGRNILine = false;

                    formScope.isAutomatedOUAllocationJournalClosed = false;
                    formScope.isAllowJournalWithoutTax = true;

                    formScope.OUType = "";

                    formScope.TotalOrigAmountExcTax = 0;
                    formScope.TotalAmountExcTax = 0;
                    formScope.TotalTaxAmount = 0;
                    formScope.PPNTaxAmount = 0;
                    formScope.PPnBMTaxAmount = 0;
                    formScope.PPHTaxAmount = 0;
                    formScope.PIAmount = 0;
                    formScope.TotalVendAmt = 0;
                    formScope.RoundingAmt = 0;

                    formScope.formDisable = false;
                    formScope.fromOpenTrans = false;
                    formScope.GLStatusDesc = "OPEN";
                    formScope.disableSmWdBtn = false;

                    formScope.headerData = new HeaderData(formInfo.source, formInfo.docType, formScope.ClientKey, formScope.CompKey, formScope.OUKey, formScope.OUCode);
                    formScope.gridSelected = new DetailData(formInfo.source, formInfo.docType, formScope.ClientKey, formScope.CompKey, formScope.OUKey, formScope.OUCode);

                    formScope.hdrKey = formScope.headerData.TransHdrKey;

                    if (formInfo.isAP || formInfo.isAR)
                    {
                        formScope.detailContactData = new ContactData(formInfo.source, formInfo.docType, formScope.ClientKey, formScope.CompKey, formScope.OUKey, formScope.OUCode);
                    }

                    if (formInfo.hasOneTimeVendor)
                    {
                        formScope.OTVendorData = new OneTimeVendorData();
                    }

                    return initVar;
                }());
            })();

            //data sources and functions
            (function ()
            {
                if (formInfo.hasUOM)
                {
                    formScope.uomDataSource = $rootScope.GetDataSource("uom");
                    dataSources.push(formScope.uomDataSource);
                }

                if (formInfo.hasPayterm)
                {
                    formScope.payTermDataSource = $rootScope.GetDataSource("payterm");
                    dataSources.push(formScope.payTermDataSource);
                }

                if (formInfo.hasCurrency)
                {
                    formScope.currInfoDataSource = $rootScope.GetDataSource("currinfo");
                    dataSources.push(formScope.currInfoDataSource);
                }

                if (formInfo.hasExchangeRate)
                {
                    //special case, don't read first, just declare
                    formScope.exRateDataSource = httpService.getDataExRate();
                }

                if (formInfo.hasCompany)
                {
                    formScope.companyDataSource = $rootScope.GetDataSource("company");
                    dataSources.push(formScope.companyDataSource);
                }

                if (formInfo.hasOU)
                {
                    formScope.ouDataSource = $rootScope.GetDataSource("ou");
                    dataSources.push(formScope.ouDataSource);
                }

                if (formInfo.hasCOA)
                {
                    formScope.accDataSource = $rootScope.GetDataSource("coa");
                    dataSources.push(formScope.accDataSource);
                }

                if (formInfo.hasContact)
                {
                    formScope.contactDataSource = $rootScope.GetDataSource("contact");
                    dataSources.push(formScope.contactDataSource);
                }

                if (formInfo.hasContactCOA)
                {
                    formScope.contactAccDataSource = $rootScope.GetDataSource("coa");
                    dataSources.push(formScope.contactAccDataSource);
                }

                if (formInfo.hasCCID)
                {
                    formScope.CCIDDataSource = $rootScope.GetDataSource("ccid");
                    dataSources.push(formScope.CCIDDataSource);
                }

                if (formInfo.hasTax)
                {
                    formScope.taxCodeDataSource = $rootScope.GetDataSource("tax");
                    dataSources.push(formScope.taxCodeDataSource);
                }

                if (formInfo.hasAccSetting)
                {
                    formScope.finConfigDataSource = $rootScope.GetDataSource("fin");
                    dataSources.push(formScope.finConfigDataSource);
                }

                if (formInfo.hasOneTimeVendor)
                {
                    formScope.empyDataSource = $rootScope.GetDataSource("empy");
                    dataSources.push(formScope.empyDataSource);
                }

                if (formInfo.hasAprovalSetting)
                {
                    formScope.aprvLvlDataSource = $rootScope.GetDataSource("aprvlvl");
                    dataSources.push(formScope.aprvLvlDataSource);
                }

                if (formInfo.hasAdjustment)
                {
                    formScope.adjAccDataSource = $rootScope.GetDataSource("coa");
                    dataSources.push(formScope.adjAccDataSource);

                    formScope.adjCCIDDataSource = $rootScope.GetDataSource("ccid");
                    dataSources.push(formScope.adjCCIDDataSource);
                }

                if (formInfo.hasInterOUAlloc)
                {
                    formScope.accAllocDataSource = $rootScope.GetDataSource("coa");
                    dataSources.push(formScope.accAllocDataSource);

                    formScope.CCIDAllocDataSource = $rootScope.GetDataSource("ccid");
                    dataSources.push(formScope.CCIDAllocDataSource);
                }

                //trigger read
                dataSources.forEach(dataSource => promises.push(dataSource.read()));

                //declare reload function onto scope
                formScope.base.reloadDataSource = function ()
                {
                    return dataSources.map(dataSource => dataSource.read());
                }

                formScope.base.update = function ()
                {
                    if (formInfo.hasOU)
                    {
                        let ouInfo = formScope.ouDataSource.data().find(v => v.OUKey == formScope.headerData.OUKey);

                        if (typeof ouInfo !== "undefined")
                        {
                            formScope.isAllowJournalWithoutTax = ouInfo.EnableNoTaxChecking;

                            formScope.OUKey = ouInfo.OUKey;
                            formScope.OUCode = ouInfo.OUCode;
                            formScope.OUType = ouInfo.OperationTypeCode;
                            formScope.CompKey = ouInfo.CompKey;

                            formScope.headerData.CompKey = ouInfo.OUKey;
                            formScope.headerData.OUKey = ouInfo.OUCode;
                            formScope.headerData.OUCode = ouInfo.CompKey;
                        }
                    }

                    return new Promise((resolve, reject) =>
                    {
                        let exratePromise = $q.defer();
                        let updatePromise = [];

                        //update currency and exchange rate
                        if (formInfo.hasCurrency && formInfo.hasCompany && formInfo.hasExchangeRate)
                        {
                            let currencyInfo = formScope.currInfoDataSource.data().find(v => v.CompKey == formScope.headerData.CompKey);
                            let companyInfo = formScope.companyDataSource.data().find(v => v.CompKey == formScope.headerData.CompKey);
                            let exRateInfo = formScope.exRateDataSource.data().find(v => v.CurrKey == formScope.headerData.CurrKey);

                            formScope.exRateDataSource.readWithURL($rootScope.masterOdataUrl + "ExRateInfo?Date=" + kendo.toString(formScope.headerData.GLDate, 'd') + "&OUKey=" + formScope.headerData.OUKey).then(function ()
                            {
                                if (typeof currencyInfo !== "undefined" && typeof companyInfo !== "undefined" && typeof exRateInfo !== "undefined")
                                {
                                    if (!currencyInfo.IsMultiCurrency)
                                    {
                                        formScope.singleCurrFlag = !currencyInfo.IsMultiCurrency;

                                        formScope.taxCurrKey = currencyInfo.LocalCurrKey;
                                        formScope.funcCurrKey = currencyInfo.LocalCurrKey;
                                        formScope.convertType = "/";

                                        formScope.headerData.CurrKey = currencyInfo.LocalCurrKey;
                                        formScope.headerData.CurrCode = currencyInfo.LocalCurrCode;
                                        formScope.headerData.ExRateToFunc = 1;
                                        formScope.headerData.ExRateToLocal = 1;
                                    }
                                    else
                                    {
                                        formScope.singleCurrFlag = !currencyInfo.IsMultiCurrency;

                                        formScope.taxCurrKey = companyInfo.TaxCurrKey;
                                        formScope.funcCurrKey = companyInfo.FuncCurrKey;
                                        formScope.convertType = companyInfo.ConvertType;

                                        formScope.headerData.CurrKey = exRateInfo.CurrKey;
                                        formScope.headerData.CurrCode = exRateInfo.CurrCode;
                                        formScope.headerData.ExRateToFunc = exRateInfo.ExRate;
                                        formScope.headerData.ExRateToLocal = exRateInfo.LocalExRate;
                                    }
                                }
                                else
                                {
                                    formScope.singleCurrFlag = true;

                                    formScope.taxCurrKey = currencyInfo.LocalCurrKey;
                                    formScope.funcCurrKey = currencyInfo.LocalCurrKey;
                                    formScope.convertType = "/";

                                    formScope.headerData.CurrKey = currencyInfo.LocalCurrKey;
                                    formScope.headerData.CurrCode = currencyInfo.LocalCurrCode;
                                    formScope.headerData.ExRateToFunc = 1;
                                    formScope.headerData.ExRateToLocal = 1;
                                }

                                formScope.comboBoxCurrencyCode.value(formScope.headerData.CurrKey);

                                finService.updateCurrInfo(formScope.gldetailGrid.dataSource.data(), formScope.headerData.CurrCode, formScope.headerData.CurrKey);

                                formScope.gldetailGrid.dataSource.data().forEach(x =>
                                {
                                    if (x.OrigDrAmt != 0)
                                    {
                                        x.OrigTransAmt = x.OrigDrAmt.NtoDP(2);
                                    }

                                    if (x.OrigCrAmt != 0)
                                    {
                                        x.OrigTransAmt = x.OrigCrAmt.Nnegated2();
                                    }

                                    if (x.Ind != "T" && x.Ind != "TN" && x.Ind != "I" && x.Ind != "CW")
                                    {
                                        x.ExRateFunc = formScope.headerData.ExRateToFunc;
                                        x.ExRateLocal = formScope.headerData.ExRateToLocal;
                                    }

                                    if (x.Ind == "O")
                                    {
                                        let TaxLine = formScope.gldetailGrid.dataSource.data().find(y => y.Ind.startsWith("T") && y.SeqNo == x.SeqNo);

                                        if (typeof TaxLine !== "undefined")
                                        {
                                            updatePromise.push(finService.updateTaxFunc(formInfo.source, formScope.headerData, formScope.gldetailGrid, (formInfo.isAP ? "PURCHASE" : "SALES"), formScope.singleCurrFlag, x.SeqNo, "TaxRecalc", formScope.convertType));
                                        }
                                    }
                                });

                                exratePromise.resolve();
                            });
                        }
                        else
                        {
                            exratePromise.resolve();
                        }

                        //wait for exchange rate to finish reading
                        exratePromise.then(function ()
                        {
                            //update total and vendor line
                            $q.all(updatePromise).then(function ()
                            {
                                let TotalAmountExcludeTax = 0;
                                let TotalTaxAmount = 0;
                                let TotalTaxAmountOriginalCurrency = 0;
                                let PPNTaxAmount = 0;
                                let PPHTaxAmount = 0;
                                let PPNBMTaxAmount = 0;
                                let RoundingAmount = 0;

                                formScope.gldetailGrid.dataSource.data().forEach(x =>
                                {
                                    if (x.Ind == "O" || x.Ind == "I" || x.Ind == "CW")
                                    {
                                        TotalAmountExcludeTax = TotalAmountExcludeTax.Nadd2(parseFloat(x.OrigDrAmt)).Nadd2(parseFloat(x.OrigCrAmt.Nnegated2()));
                                    }

                                    if (x.Ind.startsWith("T"))
                                    {
                                        TotalTaxAmount = TotalTaxAmount.Nadd2(parseFloat(x.OrigDrAmt)).Nadd2(parseFloat(x.OrigCrAmt.Nnegated2()));
                                    }

                                    if (x.Ind == "RA")
                                    {
                                        //should have only 1 line, don't sum
                                        RoundingAmount = parseFloat(x.OrigTransAmt).NtoDP(2);
                                    }

                                    //indo stuff
                                    if (x.RefTax == "W")
                                    {
                                        PPHTaxAmount = PPHTaxAmount.Nadd2(parseFloat(x.OrigDrAmt)).Nadd2(parseFloat(x.OrigCrAmt.Nnegated2()));
                                    }
                                    if (x.RefTax == "G")
                                    {
                                        PPNTaxAmount = PPNTaxAmount.Nadd2(parseFloat(x.OrigDrAmt)).Nadd2(parseFloat(x.OrigCrAmt.Nnegated2()));
                                    }
                                    if (x.RefTax == "B")
                                    {
                                        PPNBMTaxAmount = PPNBMTaxAmount.Nadd2(parseFloat(x.OrigDrAmt)).Nadd2(parseFloat(x.OrigCrAmt.Nnegated2()));
                                    }
                                });

                                if (formScope.convertType == "*")
                                {
                                    TotalTaxAmountOriginalCurrency = TotalTaxAmount.Ndiv2(formScope.headerData.ExRateToFunc);
                                }
                                else if (formScope.convertType == "/")
                                {
                                    TotalTaxAmountOriginalCurrency = TotalTaxAmount.Ntimes2(formScope.headerData.ExRateToFunc);
                                }

                                formScope.TotalOrigAmountExcTax = TotalAmountExcludeTax;
                                formScope.TotalAmountExcTax = TotalAmountExcludeTax;
                                formScope.TotalTaxAmount = TotalTaxAmount;
                                formScope.PPNTaxAmount = PPNTaxAmount;
                                formScope.PPnBMTaxAmount = PPNBMTaxAmount;
                                formScope.PPHTaxAmount = PPHTaxAmount;
                                formScope.PIAmount = TotalAmountExcludeTax.Nadd2(TotalTaxAmountOriginalCurrency).Nadd2(RoundingAmount);
                                formScope.TotalVendAmt = TotalAmountExcludeTax.Nadd2(TotalTaxAmountOriginalCurrency).Nadd2(RoundingAmount);
                                formScope.RoundingAmt = RoundingAmount;

                                if (formInfo.isAP || formInfo.isAR)
                                {
                                    finService.updateVendorInfo(formScope.headerData, formScope.detailContactData, formScope.gldetailGrid.dataSource.data(), formScope.convertType, formScope.fromOpenTrans);
                                }

                                formScope.base.updateButtonAndField();
                                formScope.base.digest();
                                resolve();
                            }, function ()
                            {
                                formScope.base.updateButtonAndField();
                                formScope.base.digest();
                                reject();
                            });
                        });
                    });
                }

                formScope.base.updateButtonAndField = function ()
                {
                    if (formInfo.hasOU)
                    {
                        let ouInfo = formScope.ouDataSource.data().find(v => v.OUKey == formScope.headerData.OUKey);

                        if (typeof ouInfo !== "undefined")
                        {
                            formScope.isAllowJournalWithoutTax = ouInfo.EnableNoTaxChecking;

                            formScope.OUKey = ouInfo.OUKey;
                            formScope.OUCode = ouInfo.OUCode;
                            formScope.OUType = ouInfo.OperationTypeCode;
                            formScope.CompKey = ouInfo.CompKey;

                            formScope.headerData.CompKey = ouInfo.OUKey;
                            formScope.headerData.OUKey = ouInfo.OUCode;
                            formScope.headerData.OUCode = ouInfo.CompKey;
                        }
                    }

                    if (formInfo.hasAprovalSetting)
                    {
                        if (formScope.headerData.Status == "OP")
                        {
                            if (formScope.aprvLvlDataSource.IsSubjectRequireApproval(formInfo.source, broadcastService.getClientKey(), formScope.headerData.CompKey, formScope.headerData.OUKey, -1, formScope.headerData.DocAmt))
                            {
                                formScope.disableSmWdBtn = false;
                            }
                            else
                            {
                                formScope.disableSmWdBtn = true;
                            }
                        }
                        else
                        {
                            formScope.disableSmWdBtn = true;
                        }
                    }

                    if (formInfo.hasCompany && formInfo.hasCurrency)
                    {
                        if (formScope.headerData.Status == "OP")
                        {
                            let companyInfo = formScope.companyDataSource.data().find(v => v.CompKey == formScope.headerData.CompKey);
                            let currencyInfo = formScope.currInfoDataSource.data().find(v => v.CompKey == formScope.headerData.CompKey);

                            if (typeof companyInfo !== "undefined" && typeof currencyInfo !== "undefined")
                            {
                                if (formScope.headerData.CurrKey == companyInfo.FuncCurrKey || (!currencyInfo.IsMultiCurrency))
                                {
                                    formScope.knExRateToFunc.enable(false);
                                }
                                else
                                {
                                    formScope.knExRateToFunc.enable(true);
                                }
                            }
                            else
                            {
                                formScope.knExRateToFunc.enable(false);
                            }
                        }
                        else
                        {
                            formScope.knExRateToFunc.enable(false);
                        }
                    }

                    if (typeof formScope.gldetailGrid.dataSource.data().find(row =>
                    {
                        if (formInfo.source == "PI")
                        {
                            return (row.Ind == "O" && (typeof row.JODetKey == "undefined"));
                        }
                        else if (formInfo.source == "SI")
                        {
                            return (row.Ind == "O" || row.Ind == "IO");
                        }
                        else
                        {
                            return row.Ind == "O";
                        }
                    }) !== "undefined")
                    {
                        formScope.hasBaseLine = true;
                    }
                    else
                    {
                        formScope.hasBaseLine = false;
                    }

                    if (formInfo.source == "PI" || formInfo.source == "CN")
                    {
                        if (typeof formScope.gldetailGrid.dataSource.data().find(row =>
                        {
                            return (row.Ind == "I" || row.Ind == "IA" || row.Ind == "CW");
                        }) !== "undefined")
                        {
                            formScope.hasGRNILine = true;
                        }
                        else
                        {
                            formScope.hasGRNILine = false;
                        }
                    }
                    else
                    {
                        formScope.hasGRNILine = false;
                    }

                    if (formScope.isAutomatedOUAllocation)
                    {
                        if (typeof formScope.headerData.closedDocNum == "string" && formScope.headerData.closedDocNum != "")
                        {
                            formScope.isAutomatedOUAllocationJournalClosed = true;
                        }
                        else
                        {
                            formScope.isAutomatedOUAllocationJournalClosed = false;
                        }
                    }
                    else
                    {
                        formScope.isAutomatedOUAllocationJournalClosed = false;
                    }

                    formScope.base.digest();
                }
            })();

            //watches
            (function ()
            {
                if (formInfo.isAP || formInfo.isAR)
                {
                    formScope.$watch('detailContactData.Remarks', function (newVal, oldVal)
                    {
                        if (newVal != oldVal)
                        {
                            finService.updateVendorInfo(formScope.headerData, formScope.detailContactData, formScope.gldetailGrid.dataSource.data(), formScope.convertType, formScope.fromOpenTrans);
                        }

                        formScope.base.updateButtonAndField();
                    });

                    formScope.$watch('headerData.EnableRoundingAdj', function (newVal, oldVal)
                    {
                        if (newVal != oldVal)
                        {
                            finService.updateVendorInfo(formScope.headerData, formScope.detailContactData, formScope.gldetailGrid.dataSource.data(), formScope.convertType, formScope.fromOpenTrans);
                        }

                        formScope.base.updateButtonAndField();
                    });
                }

                formScope.$watch('headerData.Status', function (newVal, oldVal)
                {
                    if (formScope.headerData.Status == "OP")
                    {
                        formScope.GLStatusDesc = "OPEN";
                        formScope.formDisable = false;
                    }
                    else if (formScope.headerData.Status == "SM")
                    {
                        formScope.GLStatusDesc = "SUBMITTED";
                        formScope.formDisable = true;
                    }
                    else if (formScope.headerData.Status == "PA")
                    {
                        formScope.GLStatusDesc = "PARTIALLY APPROVED";
                        formScope.formDisable = true;
                    }
                    else if (formScope.headerData.Status == "AP")
                    {
                        formScope.GLStatusDesc = "APPROVED";
                        formScope.formDisable = true;
                    }
                    else if (formScope.headerData.Status == "RJ")
                    {
                        formScope.GLStatusDesc = "REJECTED";
                        formScope.formDisable = true;
                    }
                    else if (formScope.headerData.Status == "CL")
                    {
                        formScope.GLStatusDesc = "CLOSED";
                        formScope.formDisable = true;
                    }
                    else if (formScope.headerData.Status == "VD")
                    {
                        formScope.GLStatusDesc = "VOID";
                        formScope.formDisable = true;
                    }

                    formScope.base.updateButtonAndField();
                });

                //for tax form
                formScope.$watch('headerData.TransHdrKey', function (newVal, oldVal)
                {
                    formScope.hdrKey = formScope.headerData.TransHdrKey;

                    formScope.base.updateButtonAndField();
                });

                formScope.$watch('headerData.OUKey', function (newVal, oldVal)
                {
                    formScope.base.updateButtonAndField();
                });

                formScope.$watch('headerData.DocAmt', function (newVal, oldVal)
                {
                    formScope.base.updateButtonAndField();
                });

                formScope.$watch('headerData.CurrKey', function (newVal, oldVal)
                {
                    formScope.base.updateButtonAndField();
                });
            })();
        }

        return promises;
    };

    return finFormServiceFactory;
}]);